<template>
  <tr>   
    <td style="text-align: right;">
      <v-img v-if="props.item.status == 0" width="45" src="/static/icons/monitor/barcode_cancel_96.png" alt=""/>
      <v-img v-if="props.item.status == 1" width="45" src="/static/icons/monitor/barcode_96.png" alt=""/>
      <v-img v-if="props.item.status == 2" width="45" src="/static/icons/monitor/barcode_paided_96.png" alt=""/>
    </td>
    <td class="justify-center">
      {{props.item.id}}
    </td>   
    <td class="justify-center">
      <strong style="font-size: 16px;">{{props.item.amount | money}}</strong>
    </td>
    <td class="justify-center">
      <strong>{{props.item.date_validity_formated || '--/--/--'}}</strong>
    </td>   
    <td class="justify-center">
      <strong>{{props.item.emitted_at_formated || ' Não Emitido '}}</strong>
    </td>   
    <td class="justify-center">
      <strong v-if="props.item.status == 0">{{props.item.canceled_at_formated || '--/--/--'}}</strong>
      <strong v-if="props.item.status == 1">Não pago</strong>
      <strong v-if="props.item.status == 2">{{props.item.payded_at_formated || '--/--/--'}}</strong>
    </td>   
    <td class="justify-center">
      <strong v-if="props.item.status == 0" class="finance_status canceled">Cancelado</strong>
      <strong v-if="props.item.status == 1">
        <span class="finance_status expired" v-if="props.item.date_validity_expired">Atrasado ({{props.item.date_validity_days * -1}} dias)</span>
        <span class="finance_status unpaid" v-else>Aguardando</span>
      </strong>
      <strong v-if="props.item.status == 2" class="finance_status paid">Pago</strong>
      <strong v-if="props.item.status == 4" class="finance_status unpaid">Aguardando Venda</strong>
    </td>
    <td class="justify-center">
      <strong class="finance_status">{{props.item.via}}</strong>
    </td>
    <td class="justify-center">
      {{props.item.integration_order || ' Não Emitido '}}
    </td>
    <td class="justify-center">
      {{props.item.integration_payment || ' Não Emitido '}}
    </td>
    <td class="justify-center">
      <v-menu bottom left v-if="props.item.status === 1 || props.item.integration_url">
        <v-btn slot="activator" icon>
          <v-icon>more_vert</v-icon>
        </v-btn>
        <v-list>
          <v-divider v-if="!props.item.integration_url && props.item.date_validity_days > -1 && props.item.status === 1"></v-divider>
          <v-list-tile disabled v-if="!props.item.integration_url && props.item.date_validity_days > -1 && props.item.status === 1" @click="gerarBoleto(props.item, props.refresh)">
            <v-list-tile-title><v-icon color="grey lighten-1">payment</v-icon> Gerar Boleto</v-list-tile-title>
          </v-list-tile>
          <v-divider v-if="props.item.date_validity_days < 0 && props.item.status > 0"></v-divider>
          <v-list-tile v-if="props.item.date_validity_days < 0 && props.item.status > 0" @click="createPayment()">
            <v-list-tile-title><v-icon color="grey lighten-1">update</v-icon> Gerar {{props.item.via + 1}}° Via </v-list-tile-title>
          </v-list-tile>
          <v-divider v-if="props.item.integration_url"></v-divider>
          <v-list-tile v-if="props.item.integration_url" @click="open(props.item.integration_url, '_blank')">
            <v-list-tile-title><v-icon color="grey lighten-1">payment</v-icon> Abrir Boleto</v-list-tile-title>
          </v-list-tile>
          <v-divider v-if="props.item.integration_checkout"></v-divider>
          <v-list-tile v-if="props.item.integration_checkout" @click="open(props.item.integration_checkout, '_blank')">
            <v-list-tile-title><v-icon color="grey lighten-1">payment</v-icon> Wirecard Checkout </v-list-tile-title>
          </v-list-tile>
          <v-divider v-if="props.item.integration_order"></v-divider>
          <v-list-tile v-if="props.item.integration_order" @click="updatePaymentStatus()">
            <v-list-tile-title>
              <v-icon color="orange lighten-1">refresh</v-icon>
              <span> Atualizar Status </span>
            </v-list-tile-title>
          </v-list-tile>
          <v-divider v-if="props.item.status !== 2"></v-divider>
          <v-list-tile disabled v-if="props.item.status !== 2" @click="openEditModal('pessoa-plano-parcela-pagar', props.item)">
            <v-list-tile-title><v-icon color="green lighten-1">check</v-icon> Marcar como pago</v-list-tile-title>
          </v-list-tile>
          <v-divider v-if="props.item.status !== 0"></v-divider>
          <v-list-tile disabled v-if="props.item.status !== 0" @click="openEditModal('pessoa-plano-parcela-cancelar', props.item)">
            <v-list-tile-title><v-icon color="red lighten-1">close</v-icon> Cancelar</v-list-tile-title>
          </v-list-tile>
          <v-divider v-if="props.item.status !== 1"></v-divider>
          <v-list-tile disabled v-if="props.item.status !== 1" @click="openEditModal('pessoa-plano-parcela-aguardando', props.item)">
            <v-list-tile-title><v-icon color="blue lighten-1">access_time</v-icon> Marcar como Aguardando</v-list-tile-title>
          </v-list-tile>
          <!-- <v-divider v-if="props.item.date_validity_days < 0 && props.item.status === 1"></v-divider>
          <v-list-tile v-if="props.item.date_validity_days < 0 && props.item.status === 1" @click="openEditModal('pessoa-plano-parcela-spc', props.item)">
            <v-list-tile-title><v-icon color="red lighten-1">gavel</v-icon> Editar no SPC</v-list-tile-title>
          </v-list-tile> -->
          <v-divider></v-divider>
        </v-list>
      </v-menu>
    </td>
  </tr>
</template>
<script>
  export default {
    name: 'FinancesPayments',
    methods: {
      open: function (url, style) {
        window.open(url, style)
      },
      createPayment: function () {
        console.log(this.config);
        this.$WiApiPost({
          uri: 'finance/payment',
          data: {
            finance_id: this.props.item.finance_id
          },
          callback: () => {
            this.config.refresh()
          },
          config: {
            msgAsk: true,
            msgLoad: true,
            msgSucess: true
          }
        })
      },
      updatePaymentStatus: function () {
        this.$WiApiPost({
          uri: `finance/payment/${this.props.item.id}/check`,
          callback: () => {
            this.config.refresh()
          },
          config: {
            msgAsk: {
              title: 'Atualizar o status?',
              text: 'Isso não poderá ser desfeito!'
            },
            msgLoad: true,
            msgSucess: true,
            msgError: true
          }
        })
      }
    },
    props: {
      config: Object,
      props: Object
    }
  }
</script>
<style>
.finance_status {
  border-width: 1px;
  border-style: solid;
  padding: 2px 5px;
}
.canceled {
  color: grey;
  background: #eceff1;
}
.unpaid {
  color: #ff6d00;
  background: #f0f4c3;
}
.expired {
  color: #F44336;
  background: #ffe7e5;
}
.paid {
  color: green;
  background: #b9f6ca;
}
.parcel {
  color: white;
  background: #000;
  min-width: 44px !important;
  width: 44px !important;
}
td {
  padding: 0 5px !important;
}
th {
  padding: 0 5px !important;
}
</style>